<script setup lang="ts">

</script>

<template>
  <div class="w-screen h-screen">
    <slot />
  </div>
</template>

<style scoped>

</style>